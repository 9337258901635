<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>查看详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="9">
            <a-form-item label="委托机构（甲方）">
              {{ info.partyAsName }}
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="受托机构（乙方）">
              {{ info.partBsInfo ? info.partBsInfo.partyName : "-" }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="受托机构（丙方）">
              {{ info.partCsInfo ? info.partCsInfo.partyName : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="协议签订/解除">
              {{
                info.contractType === "三方合作协议解除"
                  ? "协议解除"
                  : "协议签订"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="经营地区">
              <div v-if="info.partCsInfo">
                {{
                  info.partCsInfo.corporationJurisdiction
                    ? info.partCsInfo.corporationJurisdiction
                    : "-"
                }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="资金规模">
              {{ info.capitalScale ? info.capitalScale : " - " }}元
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="审核状态">
              {{ info.auditStatus ? info.auditStatus : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item
              :label="
                info.contractStatus === '待签署'
                  ? '三方协议(签署中)'
                  : '三方协议'
              "
            >
              <div class="link__url">
                <a
                  v-if="info.fileForm"
                  :href="imageBaseUrl + info.fileForm.fileUrl"
                >
                  {{ info.fileForm.fileName }}
                </a>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="合作起止时间">
              <span v-if="info.contractStartTime">
                {{ dtFormat(info.contractStartTime) }}
              </span>
              -
              <span v-if="info.contractEndTime">
                {{ dtFormat(info.contractEndTime) }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="18">
            <a-divider />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="甲方审核状态">
              {{ info.partyAsApproveStatus }}
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="签署状态">
              {{ info.partyAsSignStatus }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="审核意见">
              <div>
                {{
                  info.partyAsReviewComments ? info.partyAsReviewComments : "-"
                }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="乙方审核状态">
              <div v-if="info.partBsInfo">
                {{
                  info.partBsInfo.partyApproveStatus
                    ? info.partBsInfo.partyApproveStatus
                    : "-"
                }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="签署状态">
              <div v-if="info.partBsInfo">
                {{
                  info.partBsInfo.partySignStatus
                    ? info.partBsInfo.partySignStatus
                    : "-"
                }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="审核意见">
              <div v-if="info.partBsInfo">
                {{
                  info.partBsInfo.partyReviewComments
                    ? info.partBsInfo.partyReviewComments
                    : "-"
                }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="丙方审核状态">
              <div v-if="info.partCsInfo">
                {{
                  info.partCsInfo.partyApproveStatus
                    ? info.partCsInfo.partyApproveStatus
                    : "-"
                }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="签署状态">
              <div v-if="info.partCsInfo">
                {{
                  info.partCsInfo.partySignStatus
                    ? info.partCsInfo.partySignStatus
                    : "-"
                }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="审核意见">
              <div v-if="info.partCsInfo">
                {{
                  info.partCsInfo.partyReviewComments
                    ? info.partCsInfo.partyReviewComments
                    : "-"
                }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-layout-content>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import { fetchTripartiteAgreementDetail } from "@/services/AgreementService";
import { imageBaseUrl } from "@/services/HttpService";
import { dtFormat } from "@/components/DateUtils";

export default {
  name: "TripartiteAgreementDetail",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      contractNo: this.$route.params.contractNo,
      info: {},
      imageBaseUrl,
      dtFormat
    };
  },
  mounted() {
    this.loadDetail();
  },
  methods: {
    // 加载详情
    loadDetail() {
      fetchTripartiteAgreementDetail(this.contractNo).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 100vh;
  background-color: white;
  margin: 30px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 126.1px !important;
}

/deep/ .ant-form-item {
  display: flex;
  margin-right: 40px;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
}

.link__url {
  width: calc(100% - 126.1px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
